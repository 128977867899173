import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableLoadable';
import Accordion from 'components/accordion';
import Socials from 'components/socials';
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/gaming-cpu"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    ccpubottleneck: file(relativePath: { eq: "cover/gpu/cpubottleneck.png"}) { ...eyecatchImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    allGamingCpuJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
        rank
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ゲームにおすすめのCPUランキングベスト88`}</h1>
    <time itemProp="datePublished" dateTime="Sat Aug 24 2024 01:01:07 GMT+0900 (日本標準時)">更新日:2024年8月24日</time>
    <Socials {...props} mdxType="Socials" />
    <p>{`ゲームで滑らかな映像を実現するためには、`}<strong parentName="p"><em parentName="strong">{`時間と共に変わる人や物の動きをリアルタイムでいかに素早く再計算できるか`}</em></strong>{`が重要である。`}</p>
    <p>{`このような物理演算を行うにはGPUの力が大きく寄与するものの、衝突判定や背後のネットワーク処理などCPUが果たす役割もかなり大きい。`}</p>
    <p>{`短時間で複数のタスクをCPUはこなす必要があるため、マルチスレッド能力が役立つことも多いが、多くは特定のタスクに処理が集中するためか`}<strong parentName="p"><em parentName="strong">{`シングルスレッド能力がよりゲームのパフォーマンスに大きな影響を与えている`}</em></strong>{`。`}</p>
    <p>{`そこでCPUの総合的な能力を計測するベンチマークである`}<strong parentName="p"><em parentName="strong">{`PASSMARKのシングルスレッド値が高い順にランキングを付けた`}</em></strong>{`。自作やBTOパソコンの購入などで参考にして欲しい。`}</p>
    <p>{`より多くのCPUを調べたい人は`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`を参照すると良い。`}</p>
    <h2 {...{
      "id": "ゲーム用CPUの選び方で役立つリンク",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E7%94%A8CPU%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9%E3%81%A7%E5%BD%B9%E7%AB%8B%E3%81%A4%E3%83%AA%E3%83%B3%E3%82%AF",
        "aria-label": "ゲーム用CPUの選び方で役立つリンク permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲーム用CPUの選び方で役立つリンク`}</h2>
    <p>{`基本的にゲームでの性能を決めるのはCPUではなくGPUである。なので、まずは各GPUにおいてどれぐらいのフレームレートを出すことができるのかが重要となる。`}</p>
    <Card {...props} title="GPU性能比較表" description="グラフィックボードの比較を簡単に！200を超えるGPUをベンチマークやゲームの期待フレームレート(FPS)、TDP等で比較します。" image="cgrabohikaku" path="/gpu/" mdxType="Card" />
    <p>{`また、4K画質を求めるか、フレームレートを求めるかなど、`}<strong parentName="p"><em parentName="strong">{`ゲームスタイルによってCPUに求められる性能が異なってくる`}</em></strong>{`。CPUとGPUの適当な組み合わせを選ぶ上で下記の記事は参考になるだろう。`}</p>
    <Card {...props} title="CPUボトルネックを回避する" description="ゲームにおけるCPUの役割、4Kゲームとボトルネック、GPUとの組み合わせ等を解説" image="ccpubottleneck" path="/gpu/cpu-bottleneck/" mdxType="Card" />
    <h2 {...{
      "id": "ゲームにおすすめのCPUランキング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AB%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AECPU%E3%83%A9%E3%83%B3%E3%82%AD%E3%83%B3%E3%82%B0",
        "aria-label": "ゲームにおすすめのCPUランキング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームにおすすめのCPUランキング`}</h2>
    <p>{`それでは下記にランキングを示す。表の見方については適宜↓を参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <CpuMatsubi mdxType="CpuMatsubi" />
    <TableDescription mdxType="TableDescription" />
    <HowtoFilter mdxType="HowtoFilter" />
    <HikakuTable {...props} checkboxes={['corei', 'ryzen', 'notedesk']} cpuData={props.data.allGamingCpuJson.nodes} sort={{
      fieldName: 'cineR23Single'
    }} fieldArray={[{
      id: 'name',
      placeholder: '例:i7-10,i5-9',
      filterType: 'string',
      css: {
        width: '130px'
      }
    }, {
      id: 'pm'
    }, {
      id: 'pms'
    }, {
      id: 'tdp'
    }, {
      id: 'core'
    }, {
      id: 'thread'
    }, {
      id: 'clock'
    }, {
      id: 'price'
    }, {
      id: 'year'
    }, {
      id: 'cospa'
    }]} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      